import React from "react";
import Homepage from "./components/home/homepage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ContactPage from "./components/contact/contactpage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AboutPage from "./components/about/aboutpage";
import GalleryPage from "./components/gallery/gallerypage";
import PackagesPage from "./components/packages/packagespage";
import Tourpage from "./components/tour/tourpage";
import ResultPage from "./components/result/resultpage";
import ScrollToTop from "./components/ScrollToTop";
import ThankYou from "./components/tour/bookinginformation/ThankYou";
import BookingDetails from "./components/result/bookingdetails/bookingdetails";

function App() {
  return (
    <div className="App-container">
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/home" element={<Homepage />} />
            <Route path="/contactpage" element={<ContactPage />} />
            <Route path="/aboutpage" element={<AboutPage />} />
            <Route path="/gallerypage" element={<GalleryPage />} />
            <Route path="/package" element={<PackagesPage />} />
            <Route path="/tourpage" element={<Tourpage />} />
            <Route path="/tourpage#formdata" element={<BookingDetails />} />
            <Route path="/resultpage" element={<ResultPage />} />
            <Route path="/ThankYou" element={<ThankYou />} />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </div>
  );
}

export default App;
