import React from "react";
import Header from "../common/header";
import Banner from "../home/banner/banner";
import Goldentemple from "../home/goldentemple/goldentemple";
import "../home/homepage.css";
import Card from "./card/card";
import TourPackage from "./tourpackage/tourpackage";
import CustomSliders from "./feature/CustomSliders";
import AboutSikh from "./aboutsikh/aboutsikh";
import Footer from "../common/footer";
function Homepage() {
  return (
    <div className="home-container">
      <Header />
      <Banner />
      <Goldentemple />
      <Card />
      <TourPackage/>
      <CustomSliders />
      <AboutSikh />
      <Footer />
    </div>
  );
}

export default Homepage;
