import React, { useEffect, useState } from "react";
import "../tourpackage/tourpackage.css";
import { Link, useNavigate } from "react-router-dom";
import { getTours } from "../../../server";
function TourPackage() {
  const [tours, setTours] = useState([]);
  const navigate = useNavigate();
  const getData = async () => {
    const TourData = await getTours();
    if (TourData["status"] == 200) {
      setTours(TourData["data"]);
      //console.log(TourData);
    }
  };
  const submitForm = (tour, day,name) => {
    ///packagespage
    navigate("/package", {
      state: {
        //dayId: day,
        dayId: day,
        tour: tour,
        name:name,
      },
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <section className="main_container tourpackage-container" id="packages">
      <div className="inner_container">
    
        <div className="tourpackage-content container ">
          <p>JAWAR TAIBAH</p>
          <h1 className="main_heading m_b_2">TOUR PACKAGES</h1>
          <div className="tourpackage_grid">
            {tours.length
              ? tours.map((t) => (
                  <div class="card packages" key={t.id}>
                    <div class="card_package_body">
                      <div className="tour-card-content">
                        <h1 className="title-1 main_heading m_b_2">{t.title}</h1>
                        {/* <div class="card-container">
                          {t.days.map((d, i) => {
                            return (
                              <>
                                <div className="timer_card">
                                  <div class="timer_card_time">
                                    {d.toString().padStart(2, "0")}
                                  </div>
                                  <div className="timer_card_days">Days</div>
                                </div>
                                {i !== t.days.length - 1 ? (
                                  <img
                                    src="/slash.png"
                                    className="slash mx-3"
                                    alt="slash"
                                  />
                                ) : null}
                              </>
                            );
                          })}
                        </div> */}

                        {/* <h6 class="card-subtitle ">{t.sub_title}</h6>
                        <p class="card-text">{t.description}</p> */}
                        <div className="detailed-buttons">
                          
                       {t.days.map((d, index) => {
                    const name = t.name[index];
                    return (
                      <button key={index} onClick={() => submitForm(t, d,t.name[index])}>
                        {name} 
                      </button>
                    );
                  })}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
    
      </div>
   
    </section>
  );
}

export default TourPackage;
