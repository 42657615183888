import React from 'react'
import "../banner6/banner6.css"
function Banner6() {
  return (
    <div className='banner6-container text-center'>
            <div className='banner6-content'>
                <p>Booking Successful</p>
                <h1 >THANK YOU FOR YOUR BOOKING</h1>
            </div>
            
    </div>
  )
}

export default Banner6