import React from "react";
import "./banner.css";
import { HashLink } from "react-router-hash-link";

function Banner() {
  return (
    <div className="banner-container text-center">
      <div className="banner-content">
        <h3>Welcome to the Sacred Land</h3>
        
        <p>
        We are specialist, locally aware and have knowledge of all the
         places in and around Saudi Ziarats.
        </p>
        <HashLink to="#packages">
          <button type="Submit">See Packages</button>
        </HashLink>
      </div>
    </div>
  );
}

export default Banner;
