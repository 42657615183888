import React, { useState } from "react";
import "../form/form.css";
import { useNavigate } from "react-router-dom";

function Form() {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    name: "",
    contact: "",
    email: "",
    company: "",
    feedback: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Thanks for your feedback. One of our agents will contact you soon.");
    navigate("/");
    // You can access other user properties like user.contact, user.email, etc. here.
  };

  return (
    <div className="form-container">
      <div className="row form-r">
        <div className="col questions">
          <h1>
            Have Questions?<br></br>Get In Touch!
          </h1>
        </div>
        <div className="col">
          <form onSubmit={handleSubmit}>
            <div className="row r1">
              <div className="col-12 col-md-6">
                <input
                  type="text"
                  name="name"
                  value={user.name}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="NAME"
                  required
                />
              </div>
              <div className="col-12 col-md-6">
                <input
                  type="text"
                  name="contact"
                  value={user.contact}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="CONTACT"
                  required
                />
              </div>
            </div>

            <div className="row r2">
              <div className="col-12 col-md-6">
                <input
                  type="text"
                  name="email"
                  value={user.email}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="EMAIL"
                  required
                />
              </div>
              <div className="col-12 col-md-6">
                <input
                  type="text"
                  name="company"
                  value={user.company}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="COMPANY"
                  required
                />
              </div>
            </div>

            <div className="row  r3">
              <div className="col-12">
                <textarea
                  name="feedback"
                  style={{ height: "15vh" }}
                  value={user.feedback}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="ANY QUESTIONS? PLEASE FEEL FREE TO ASK"
                  required
                ></textarea>
              </div>
            </div>

            <div className="row r4">
              <div className="col">
                <button type="submit">Send Message</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Form;
