import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Image4 from "../../assets/Layer1.svg";
import Image5 from "../../assets/Layer2.svg";
import Image6 from "../../assets/Layer3.svg";
import Image7 from "../../assets/Layer4.svg";
import Image8 from "../../assets/hotel-alt.svg";
import Modal from 'react-modal';
import SwiperCore from "swiper";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "./hotels.css";
import { RxCross1 , RxStarFilled} from "react-icons/rx";
import { CurrencyConversionFn, fetchHotels, getRoomsDetail } from "../../../server";
import RoomModal from "./RoomModal";
import { CURRENCY_CODE } from "../../../cardsContent";
// Initialize SwiperCore modules
SwiperCore.use([Navigation]);
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    maxWidth:'650px',
    transform: 'translate(-50%, -50%)',
    maxHeight:"80vh"
  },
};
const Hotels = ({chkIn, chkOut, star, FinalBooking, setFinalBooking}) => {
  const [hotelsList, setHotelList]=useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [Rooms, setRooms] = useState(false);
  const [ConversionRate, setConversionRate] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

 async function selectHotelFn(hotel){
  //console.log(hotel);
  try{
    const data= await getRoomsDetail(hotel.AccommodationId[0], chkIn, chkOut);
    console.log(data);
    setRooms({...data[0], ImageURL:hotel.ImageURL, AccommodationName:hotel.AccommodationName, star:hotel['Rating'][0]});
    openModal();

  }catch(e){
    console.log(e);
  }
  }
  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    const swiper = new SwiperCore(".swiper-container-hotel", {
      slidesPerView: 3,
      spaceBetween: 30,
      navigation: {
        prevEl: ".custom-prev-arrow",
        nextEl: ".custom-next-arrow",
      },
    });
  }, [hotelsList]);

  const fetchBasicData= async()=>{
    try{
      const data= await fetchHotels(chkIn, chkOut, star);
      const convertedRate= await CurrencyConversionFn();
      setConversionRate(Number(convertedRate['ConversionRate'][0]));
      setHotelList(data);
    }catch(e){
     console.log(e)
    }

  }

  useEffect(()=>{
    fetchBasicData();
  }, [])


  return (
    <div className="book-tour-container box_shadow">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >

        <div className="modal_header">
        <div className="title">Room DETAILS</div>
        <RxCross1 onClick={closeModal}>x</RxCross1>
        </div>
        <div className="modal_boday">
        {Rooms?       
         <RoomModal Rooms={Rooms} FinalBooking={FinalBooking} setFinalBooking={setFinalBooking} ConversionRate={ConversionRate}/>
        :null}
        </div>
      
      </Modal>

      <div className="banner">
        <img src={Image8}></img>
        <span> Booking Details</span>
      </div>
      <div className="hotel-booking">
        {
        hotelsList.length?
             <div className="swiper-container-hotel">
             <div className="swiper-wrapper">
   
               {
              hotelsList.map((h, i)=>{
                var findData= FinalBooking.hotelData?FinalBooking.hotelData.find(o=>o.hotel_id==h.AccommodationId[0]):'';

                  return(<div className="swiper-slide" key={h.AccommodationName}>
                   <div class="card w-100">
                     <img class="card-img-top" src={h.ImageURL} alt="Card image cap" />
                     <div class="card-body text-center">
                       <h1 class="card_title text-center">{h.AccommodationName}</h1>
                       <h3 className="card_price">Price Starting from {CURRENCY_CODE}{"."}{Number(h.MinRate[0]).toLocaleString()}</h3>


                       <h3 className="card_star">
                        {Array.from({ length: h['Rating'][0] }, (_, index) => (
                          <RxStarFilled key={index}/>
                        ))}
                       </h3>
                       <p className="card_desc">{h['GeneralDescription'][0]}
                       </p>
                       <div className="hotel-svgs">
                         <img src={Image4}></img>
                         <img src={Image5}></img>
                         <img src={Image6}></img>
                         <img src={Image7}></img>
                       </div>
                       <div className={findData?"selected-hotel":"select-hotel"}>
                         <button onClick={()=>selectHotelFn(h)}>Select</button>
                       </div>
                     </div>
                   </div>
                 </div>
                 )})
                        }
             </div>
             <div className="custom-next-arrow">
               <FaArrowRight />
             </div>
             <div className="custom-prev-arrow">
               <FaArrowLeft />
             </div>
           </div>
             :<LoadingCards />
        }
  
      </div>
    </div>
  );
};


// to show loading container
export const LoadingCards=()=>(
  <div className="loading_grid">
           <div class="card w-100">
                  <Skeleton  class="card-img-top" height={200} borderRadius={18}/>
                  <div class="card-body text-center">
                    <h1 class="card-text text-center"><Skeleton /></h1>
                    <h3 className="card-text"><Skeleton /></h3>
                    <p className="card-text"><Skeleton /></p>
                    <div className="select-hotel">
                      <Skeleton style={{width:"50%",height:"40px", margin:"0 auto"}}/>
                    </div>
                  </div>
        </div>
        <div class="card w-100">
                  <Skeleton  class="card-img-top" height={200} borderRadius={18}/>
                  <div class="card-body text-center">
                    <h1 class="card-text text-center"><Skeleton /></h1>
                    <h3 className="card-text"><Skeleton /></h3>
                    <p className="card-text"><Skeleton /></p>
                    <div className="select-hotel">
                      <Skeleton style={{width:"50%",height:"40px", margin:"0 auto"}}/>
                    </div>
                  </div>
        </div>
        <div class="card w-100">
                  <Skeleton  class="card-img-top" height={200} borderRadius={18}/>
                  <div class="card-body text-center">
                    <h1 class="card-text text-center"><Skeleton /></h1>
                    <h3 className="card-text"><Skeleton /></h3>
                    <p className="card-text"><Skeleton /></p>
                    <div className="select-hotel">
                      <Skeleton style={{width:"50%",height:"40px", margin:"0 auto"}}/>
                    </div>
                  </div>
        </div>
  </div>
  
)

export default Hotels;
