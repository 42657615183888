import React from "react";
import Image1 from "../../assets/inside-haram.jpg";
import Image2 from "../../assets/about-icon-1.png";
import Image3 from "../../assets/about-icon-2.png";
import "../aboutsikh/aboutsikh.css";
function AboutSikh() {
  return (
    <div className="aboutsikh-container">
      <div className="row first">
        <div className="col-5">
          <img className="guru" src={Image1}></img>
        </div>
        <div className="col-7 sm-12">
          <div className="sikhabout-content">
            <span>JAWAR TAIBAH</span>
            <h1 className="main_heading m_b_2" style={{paddingTop:"15px"}}>
              MUSLIMS ARE THOSE WHO BELIEVES IN HUMANITY
            </h1>
            <div className="humanity">
              <p style={{ color: "black", fontWeight: "500" }}>
              We are specialist, locally aware and have knowledge of all the places in and around Saudi Ziarats.
              </p>
            </div>
          </div>
          <div className="row second">
            <div className="another-container">
              <div className="row">
                {" "}
                <div className="image-with-text">
                  <img src={Image2} alt="Image 2" />
                  <div className="text place">
                    <h5>PLACE OF PEACE</h5>
                    <p>
                    Makkah is a place where Muslims can come to find peace, tranquility, and spiritual growth. It is a city that is dedicated to worship and the pursuit of a closer relationship with Allah.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="another-container2">
              <div className="row">
                <div className="image-with-text">
                  <img src={Image3} alt="Image 3" />
                  <div className="text place">
                    <h5>CONNECTS DEVOTERS</h5>
                    <p>
                    Madina is a city that connects devotees to each other and to Allah. It is a place where Muslims from all over the world come together to worship, learn, and grow spiritually.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutSikh;
