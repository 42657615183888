import moment from "moment";
import React from "react";

const EmailTemplate = ({ data }) => {
  var vehicleID = data.vehicleID.split(",");
  var vehicle_price = data.vehicle_price.split(",");
  var vehicle_name = data.vehicle_name.split(",");
  var policeService_id = data.policeService_id.split(",");
  var policeService_name = data.policeService_name.split(",");
  var policeService_price = data.policeService_price.split(",");
  var bookingType = data.booking_type;
  const Days = moment(data.departure_date, "YYYY-MM-DD").diff(
    moment(data.arrival_date, "YYYY-MM-DD"),
    "days"
  );
  return (
    <form method="post" action="#">
      <div id="m_-6901037398114557470Panel1">
        <table
          border={0}
          cellPadding={0}
          cellSpacing={0}
          height="100%"
          style={{
            margin: 0,
            padding: 0,
            border: 0,
            backgroundColor: "#fff",
            fontFamily: "arial",
            width: "100%",
          }}
        >
          <tbody>
            <tr>
              <td align="center" valign="top">
                <span>
                  <font color="#888888" />
                </span>
                <table
                  border={0}
                  cellPadding={0}
                  cellSpacing={0}
                  style={{
                    width: "100%",
                    margin: 0,
                    padding: 0,
                    background: "#ffffff",
                    fontFamily: "arial",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          paddingTop: 20,
                          paddingBottom: 20,
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                        valign="top"
                      >
                        <table
                          border={0}
                          cellPadding={0}
                          cellSpacing={0}
                          style={{
                            margin: 0,
                            padding: 0,
                            border: 0,
                            width: "100%",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  textAlign: "left",
                                  fontSize: 18,

                                  fontFamily: "arial",
                                  color: "gray",
                                  fontWeight: "bold",
                                }}
                                valign="top"
                              >
                                Thank you,{" "}
                                <span id="m_-6901037398114557470xlblGuestName">
                                  {data["members"][0]["fullName"]}
                                </span>
                                ! Your reservation is now confirmed.
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingBottom: 20,
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                        valign="top"
                      >
                        <table
                          border={0}
                          cellPadding={0}
                          cellSpacing={0}
                          style={{
                            margin: 0,
                            padding: 0,
                            border: 0,
                            width: "100%",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  textAlign: "left",
                                  fontSize: 16,
                                  fontFamily: "arial",
                                  verticalAlign: "middle",
                                }}
                                valign="middle"
                              >
                                <a
                                  id="m_-6901037398114557470xlinkhotel"
                                  style={{
                                    color: "black",
                                    textDecoration: "none",
                                    paddingRight: 3,
                                  }}
                                  title="Hotel information"
                                >
                                  <b>
                                    <span id="m_-6901037398114557470xlblHotelName">
                                      JAWAR TAIBAH
                                    </span>
                                  </b>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ paddingTop: 5, textAlign: "left" }}
                                valign="top"
                              >
                                <table
                                  border={0}
                                  cellPadding={0}
                                  cellSpacing={0}
                                  style={{
                                    margin: 0,
                                    padding: 0,
                                    border: 0,
                                    width: "100%",
                                  }}
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          textAlign: "left",
                                          fontFamily: "arial",
                                          color: "#333",
                                          fontSize: 12,
                                        }}
                                        valign="top"
                                      >
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{
                                            margin: 0,
                                            padding: 0,
                                            border: 0,
                                          }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  textAlign: "left",
                                                  fontFamily: "arial",
                                                  color: "#333",
                                                  fontSize: 12,

                                                  paddingRight: 10,
                                                }}
                                                valign="top"
                                              >
                                                <b>Address:</b>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{
                                                  textAlign: "left",
                                                  fontFamily: "arial",
                                                  color: "#333",
                                                  fontSize: 12,
                                                }}
                                                valign="top"
                                              >
                                                <span>
                                                  <span id="m_-6901037398114557470xlblHotelAddress">
                                                    Office # 23, Al Zugaibi
                                                    Compund, Beside Mandarine
                                                    Super Market. Abizar Gaffari
                                                    Street. Al Suhman District.
                                                    Madinah Al Munawarah
                                                  </span>
                                                </span>
                                                <br />
                                                <span>
                                                  <span id="m_-6901037398114557470xlblCityName">
                                                    Saudi Arabia
                                                    <u />
                                                  </span>
                                                </span>
                                              </td>
                                            </tr>
                                            <tr></tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td
                                        style={{ textAlign: "left" }}
                                        valign="top"
                                        width={300}
                                      >
                                        <img
                                          src="https://jawartaibah.com/static/media/JT-Logo.6b383a0e133c25684453.png"
                                          alt="Hotel information"
                                          border={0}
                                          height={90}
                                          style={{
                                            float: "right",
                                            outline: "none",
                                            textDecoration: "none",
                                            border: "none",
                                            display: "block",
                                          }}
                                          className="CToWUd"
                                          data-bit="iit"
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingBottom: 20,
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                        valign="top"
                      >
                        <table
                          border={0}
                          cellPadding={0}
                          cellSpacing={0}
                          style={{
                            margin: 0,
                            padding: 0,
                            border: 0,
                            width: "100%",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  textAlign: "left",
                                  fontFamily: "arial",
                                  fontSize: 12,

                                  borderBottom: "1px dotted #aaaaaa",
                                  paddingTop: 5,
                                  paddingBottom: 5,
                                }}
                                valign="top"
                              >
                                <b>Your reservation</b>
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  fontFamily: "arial",
                                  fontSize: 12,
                                  borderBottom: "1px dotted #aaaaaa",
                                  paddingTop: 5,
                                  paddingBottom: 5,
                                }}
                                valign="top"
                              >
                                {data["tour"][0]["title"] +
                                  " (" +
                                  data["payment_method"] +
                                  " ) "}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  textAlign: "left",
                                  fontFamily: "arial",
                                  color: "#333",
                                  fontSize: 12,
                                  borderBottom: "1px dotted #aaaaaa",
                                  paddingTop: 5,
                                  paddingBottom: 5,
                                }}
                                valign="top"
                              >
                                <b>Reservation Date</b>
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  fontFamily: "arial",
                                  color: "#333",
                                  fontSize: 12,
                                  borderBottom: "1px dotted #aaaaaa",
                                  paddingTop: 5,
                                  paddingBottom: 5,
                                }}
                                valign="top"
                              >
                                <time dateTime="2016-10-28T12:00:00+03:00">
                                  <span>
                                    <span>
                                      <span id="m_-6901037398114557470xlbleConfirmationDate">
                                        {data["reservation_date"]}
                                      </span>
                                    </span>
                                  </span>
                                </time>
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  textAlign: "left",
                                  fontFamily: "arial",
                                  color: "#333",
                                  fontSize: 12,
                                  borderBottom: "1px dotted #aaaaaa",
                                  paddingTop: 5,
                                  paddingBottom: 5,
                                }}
                                valign="top"
                              >
                                <b>Check-in</b>
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  fontFamily: "arial",
                                  color: "#333",
                                  fontSize: 12,
                                  borderBottom: "1px dotted #aaaaaa",
                                  paddingTop: 5,
                                  paddingBottom: 5,
                                }}
                                valign="top"
                              >
                                <time dateTime="2016-10-28T12:00:00+03:00">
                                  <span>
                                    <span>
                                      <span id="m_-6901037398114557470xlblCheckIn">
                                        {data.arrival_date}
                                      </span>
                                    </span>
                                  </span>
                                </time>
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  textAlign: "left",
                                  fontFamily: "arial",
                                  color: "#333",
                                  fontSize: 12,
                                  borderBottom: "1px dotted #aaaaaa",
                                  paddingTop: 5,
                                  paddingBottom: 5,
                                }}
                                valign="top"
                              >
                                <b>Check-out</b>
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  fontFamily: "arial",
                                  color: "#333",
                                  fontSize: 12,
                                  borderBottom: "1px dotted #aaaaaa",
                                  paddingTop: 5,
                                  paddingBottom: 5,
                                }}
                                valign="top"
                              >
                                <time dateTime="2016-10-29T15:00:00+03:00">
                                  <span>
                                    <span>
                                      <span id="m_-6901037398114557470xlblCheckOut">
                                        {data.departure_date}
                                      </span>
                                    </span>
                                  </span>
                                </time>
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  textAlign: "left",
                                  fontFamily: "arial",
                                  color: "#333",
                                  fontSize: 12,
                                  borderBottom: "1px dotted #aaaaaa",
                                  paddingTop: 5,
                                  paddingBottom: 5,
                                }}
                                valign="top"
                              >
                                <b>Booking Number</b>
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  fontFamily: "arial",
                                  color: "#333",
                                  fontSize: 12,
                                  borderBottom: "1px dotted #aaaaaa",
                                  paddingTop: 5,
                                  paddingBottom: 5,
                                }}
                                valign="top"
                              >
                                <span id="m_-6901037398114557470xlblMBRPinCode">
                                  {data.id}
                                </span>
                              </td>
                            </tr>
                            {/* <tr>
                              <td
                                style={{
                                  textAlign: "left",
                                  fontFamily: "arial",
                                  color: "#333",
                                  fontSize: 12,
                                  borderBottom: "1px dotted #aaaaaa",
                                  paddingTop: 5,
                                  paddingBottom: 5,
                                }}
                                valign="top"
                              >
                                <b>Total Members</b>
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  fontFamily: "arial",
                                  color: "#333",
                                  fontSize: 12,
                                  borderBottom: "1px dotted #aaaaaa",
                                  paddingTop: 5,
                                  paddingBottom: 5,
                                }}
                                valign="top"
                              >
                                <span id="m_-6901037398114557470xlblMBRPinCode">
                                  {data.members.length}
                                </span>
                              </td>
                            </tr> */}
                            {/* <tr>
                              <td
                                style={{
                                  textAlign: "left",
                                  fontFamily: "arial",
                                  color: "#333",
                                  fontSize: 12,
                                  borderBottom: "1px dotted #aaaaaa",
                                  paddingTop: 5,
                                  paddingBottom: 5,
                                }}
                                valign="top"
                              >
                                <b>Members</b>
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  fontFamily: "arial",
                                  color: "#333",
                                  fontSize: 12,
                                  borderBottom: "1px dotted #aaaaaa",
                                  paddingTop: 5,
                                  paddingBottom: 5,
                                }}
                                valign="top"
                              >
                                <span id="m_-6901037398114557470xlblMBRPinCode">
                                  {data.members.map((m) => m.fullName + ", ")}
                                </span>
                              </td>
                            </tr> */}

                            <tr>
                              <td
                                style={{
                                  textAlign: "left",
                                  fontFamily: "arial",
                                  color: "#333",
                                  fontSize: 12,
                                  borderBottom: "1px dotted #aaaaaa",
                                  paddingTop: 5,
                                  paddingBottom: 5,
                                }}
                                valign="top"
                              >
                                <b>Payment Method</b>
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  fontFamily: "arial",
                                  color: "#333",
                                  fontSize: 12,
                                  borderBottom: "1px dotted #aaaaaa",
                                  paddingTop: 5,
                                  paddingBottom: 5,
                                }}
                                valign="top"
                              >
                                <span id="m_-6901037398114557470xlblMBRPinCode">
                                  <a>Pay - Before Start</a>
                                </span>
                              </td>
                            </tr>
                            {/* <tr>
                              <td
                                style={{
                                  textAlign: "left",
                                  fontFamily: "arial",
                                  color: "#333",
                                  fontSize: 12,
                                  borderBottom: "1px dotted #aaaaaa",
                                  paddingTop: 5,
                                  paddingBottom: 5,
                                }}
                                valign="top"
                              >
                                <b>Nationality</b>
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  fontFamily: "arial",
                                  color: "#333",
                                  fontSize: 12,
                                  borderBottom: "1px dotted #aaaaaa",
                                  paddingTop: 5,
                                  paddingBottom: 5,
                                }}
                                valign="top"
                              >
                                <span id="m_-6901037398114557470xlblMBRPinCode">
                                  <a>{data["members"][0]["nationality"]}</a>
                                </span>
                              </td>
                            </tr> */}
                            <tr>
                              <td
                                style={{
                                  textAlign: "left",
                                  fontFamily: "arial",
                                  color: "#333",
                                  fontSize: 12,
                                  borderBottom: "1px dotted #aaaaaa",
                                  paddingTop: 5,
                                  paddingBottom: 5,
                                }}
                                valign="top"
                              >
                                <b>Guest Name</b>
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  fontFamily: "arial",
                                  color: "#333",
                                  fontSize: 12,

                                  borderBottom: "1px dotted #aaaaaa",
                                  paddingTop: 5,
                                  paddingBottom: 5,
                                }}
                                valign="top"
                              >
                                <span>
                                  <span id="m_-6901037398114557470xlblBookerName">
                                    {data["members"][0]["fullName"]}{" "}
                                  </span>
                                </span>
                                (
                                <span>
                                  <span id="m_-6901037398114557470xlblBookerEmail">
                                    <a
                                      href="mailto:mehwishahmed826@gmail.com"
                                      target="_blank"
                                    >
                                      {data["members"][0]["email"]}
                                    </a>
                                  </span>
                                </span>
                                )
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  textAlign: "left",
                                  fontFamily: "arial",
                                  color: "#333",
                                  fontSize: 12,

                                  borderBottom: "1px dotted #aaaaaa",
                                  paddingTop: 5,
                                  paddingBottom: 5,
                                  border: 0,
                                }}
                                valign="top"
                              >
                                <b>Guest Telephone Number</b>
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  fontFamily: "arial",
                                  color: "#333",
                                  fontSize: 12,

                                  borderBottom: "1px dotted #aaaaaa",
                                  paddingTop: 5,
                                  paddingBottom: 5,
                                  border: 0,
                                }}
                                valign="top"
                              >
                                <span id="m_-6901037398114557470xlblTelenumber">
                                  {data["members"][0]["phone"]}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingBottom: 20,
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                        valign="top"
                      >
                        <table
                          border={0}
                          cellPadding={0}
                          cellSpacing={0}
                          style={{
                            margin: 0,
                            padding: 0,
                            border: 0,
                            width: "100%",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  backgroundColor: "lightgray",
                                  border: "1px solid #cfd6e0",
                                  padding: 15,
                                }}
                                valign="top"
                              >
                                <table
                                  border={0}
                                  cellPadding={0}
                                  cellSpacing={0}
                                  style={{ margin: 0, padding: 0, border: 0 }}
                                  width="100%"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          textAlign: "left",
                                          fontFamily: "arial",
                                          fontSize: 12,

                                          borderBottom: "1px dotted #aaaaaa",
                                          paddingTop: 5,
                                          paddingBottom: 5,
                                          color: "black",
                                        }}
                                        valign="top"
                                      >
                                        <b>
                                          <div className="col-md-6 col-sm-12 billing-details px-lg-5 px-3">
                                            <h1 className="billing-details">
                                              Billing Details
                                            </h1>
                                            {/* {console.log(FinalBooking)} */}
                                            {console.log(bookingType)}
                                            {bookingType !== "static" ? (
                                              <>
                                                {/* <div className="accomodation">
                                                  <h3>ACCOMODATION</h3>
                                                  {data.hotel
                                                    ? data.hotel.map((p) => {
                                                        var hotel_price =
                                                          Number(p.price) *
                                                          p.quantity;

                                                        return (
                                                          <p>
                                                            {p.quantity > 1
                                                              ? p.quantity
                                                              : ""}{" "}
                                                            {p.room_name} for{" "}
                                                            {Days} night @ SAR
                                                            {hotel_price}/night
                                                          </p>
                                                        );
                                                      })
                                                    : null}
                                                  <p className="mt-2">
                                                    Total (incl. Tax) SAR{" "}
                                                    {data.room_charges.toLocaleString()}
                                                  </p>
                                                </div> */}
                                                <div className="accomodation">
                                                  <h3>TRANSPORT</h3>
                                                  {data.vehicleID
                                                    ? vehicleID.map((p, i) => (
                                                        <p>
                                                          {data.room_charges ||
                                                            " " + " "}
                                                          {vehicle_name[i]} for
                                                          @ SAR
                                                          {vehicle_price[i]}
                                                          /vehicle
                                                        </p>
                                                      ))
                                                    : null}
                                                  <p className="mt-2">
                                                    GST SAR{" "}
                                                    {data.vehicle_gst.toLocaleString()}{" "}
                                                    %
                                                  </p>
                                                </div>
                                                {/* {console.log(packages)} */}

                                                {/* <div className="accomodation">
                                                  <h3>POLICE SQUAD</h3>
                                                  {console.log(
                                                    policeService_price
                                                  )}
                                                  {policeService_id.map(
                                                    (p, i) => (
                                                      <p>
                                                        {policeService_name.length +
                                                          " "}
                                                        {policeService_name[i]}{" "}
                                                        for {Days} days @ SAR
                                                        {console.log(
                                                          policeService_price.map(
                                                            (d) =>
                                                              console.log(d)
                                                          )
                                                        )}
                                                        {policeService_price[
                                                          i
                                                        ] * Days}
                                                        /service
                                                      </p>
                                                    )
                                                  )}
                                                  <p className="mt-2">
                                                    Total (incl. Tax) SAR{" "}
                                                    {data.police_squad.toLocaleString()}
                                                  </p>
                                                </div> */}
                                                {/* {data.meal_title ? (
                                                  <div className="accomodation">
                                                    <h3>
                                                      MEAL Total (incl. Tax)
                                                    </h3>
                                                    <p>
                                                      {data.meal_title} for
                                                      {Days} days @ SAR
                                                      {Number(
                                                        data.meals_charges
                                                      )}{" "}
                                                      per Person
                                                    </p>
                                                    <p className="mt-2">
                                                      Total (incl. Tax) SAR{" "}
                                                      {(
                                                        data.meals_charges *
                                                        data.members.length
                                                      ).toLocaleString()}
                                                    </p>
                                                  </div>
                                                ) : null} */}
                                                {/* <div className="accomodation">
                                                  <h3>Service Charges</h3>
                                                  <div>
                                                    {" "}
                                                    SAR{" "}
                                                    {Number(
                                                      data.service_charges
                                                    ).toLocaleString()}{" "}
                                                  </div>
                                                </div> */}
                                                <div className="accomodation">
                                                  <h3>GRAND TOTAL</h3>

                                                  <div>
                                                    {" "}
                                                    SAR{" "}
                                                    {Number(
                                                      data.total
                                                    ).toLocaleString()}{" "}
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                <div className="accomodation">
                                                  <h3>
                                                    Total Charges ({" "}
                                                    {data.members.length}{" "}
                                                    persons )
                                                  </h3>
                                                  <div>
                                                    {" "}
                                                    SAR{" "}
                                                    {Number(
                                                      data.total /
                                                        data.members.length
                                                    ).toLocaleString()}{" "}
                                                    per person
                                                  </div>
                                                </div>
                                                {/* <div className="accomodation">
                                                  <h3>Service Charges</h3>
                                                  <div>
                                                    {" "}
                                                    SAR{" "}
                                                    {Number(
                                                      data.service_charges
                                                    ).toLocaleString()}{" "}
                                                  </div>
                                                </div> */}
                                                <div className="accomodation">
                                                  <h3>GRAND TOTAL</h3>
                                                  <div>
                                                    {" "}
                                                    SAR{" "}
                                                    {Number(
                                                      data.total
                                                    ).toLocaleString()}{" "}
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </b>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingBottom: 20,
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                        valign="top"
                      >
                        <table
                          id="m_-6901037398114557470xdlRoomDetail"
                          cellSpacing={0}
                          cellPadding={0}
                          align="Left"
                          style={{ width: "100%", borderCollapse: "collapse" }}
                        >
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  paddingBottom: 20,
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                }}
                                valign="top"
                              >
                                <table
                                  border={0}
                                  cellPadding={0}
                                  cellSpacing={0}
                                  style={{
                                    padding: 0,
                                    border: 0,
                                    width: "100%",
                                  }}
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          textAlign: "left",
                                          fontSize: 18,
                                          fontFamily: "arial",
                                          color: "#003580",
                                          pageBreakAfter: "avoid",
                                          fontWeight: "bold",
                                        }}
                                        valign="top"
                                      ></td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          textAlign: "left",
                                          fontFamily: "arial",
                                          color: "#333",
                                          fontSize: 12,
                                        }}
                                        valign="top"
                                      >
                                        <span id="m_-6901037398114557470xlblGuestCom" />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  textAlign: "left",
                                  color: "black",
                                  fontStyle: "italic",
                                  fontFamily: "Georgia,Serif",
                                  paddingBottom: 20,
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                }}
                                valign="top"
                              >
                                <div style={{ fontSize: 16 }}>
                                  Have a great tour!
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  paddingTop: 20,
                                  paddingBottom: 20,
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                }}
                                valign="top"
                              />
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <span>
                  <font color="#888888" />
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </form>
  );
};

export default EmailTemplate;
