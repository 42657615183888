import React from 'react'
import Image from '../../assets/Group1863.svg'
import Image2 from'../../assets/visa-4.svg'
import Image3 from '../../assets/ticket.svg'
import './bookingdetails.css'
function BookingDetails() {
  return (
    <div className='booking-detail-container'>
        <div className='booking-banner'>
            <div className='booking-banner-content'>
            <img src={Image3}></img>
            <p> Booking Details</p>
            </div>
          
          </div>
        <div className='details-container'>
            <div className='row booking-no'>
                 <img src={Image}></img>
                <p>Booking details</p>
                <p>THANK YOU,ABC</p>
            </div>
            <div className='booking-confirmation'>
                <p>Your Booking is Confirmed</p>
                <p>You purchased PLATINUM TOUR PACKAGE for 5 persons</p>
                <p>You'll receive a confirmation email shortly</p>
            </div>
            <div className='row contact-information'>
                    <h3>Booking Details</h3>
                    <h5>Contact information</h5>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
            </div>
            <div className='row payment-method'>
              <img src={Image2}></img>
            </div>
            <div className='row grand-total'>
                <p>GRAND TOTAL</p>
                <p>RS.34975</p>
            </div>
        </div>
    </div>
  )
}

export default BookingDetails