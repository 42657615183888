export const CURRENCY_CODE = "SAR";

export const Locations = [
  {
    id: 0,
    img: "/assets/madina-masjid.jpg",
    title: "MASJID AL NABAWI",
    desc: "The Prophet's Mosque or Mosque of the Prophet, is the second mosque built by the Islamic prophet Muhammad in Medina, after that of Quba, as well as the second largest mosque and holiest site in Islam, after the Masjid al-Haram in Mecca, in the Saudi region of the Hejaz.",
  },
  {
    id: 1,
    img: "/assets/masjidqiblaitain.jpg",
    title: "Masjid al-Qiblatayn",
    desc: "Masjid al Qiblatain means Masjid with two qiblas is situated in Medina, and it is historically important for Muslims and visitors to Madina, as this is the place where the Islamic prophet Muhammad,صلی الله علیہ وسلم leading the prayer, and he has been commanded by Almighty Allah (SWT) to change the direction of prayer (qibla)",
  },
  {
    id: 2,
    img: "/assets/mountain-ohud.jpg",
    title: "Mount Uhud",
    desc: "Mount Uhud is a mountain north of Medina, in the Hejazi region of Saudi Arabia. It is 1,077 m high and 7.5 km long. It was the site of the second battle between the Islamic prophet Muhammad and the polytheists of his tribe of Quraysh.At the time of Qayamah Allah will raise Uhud along with Kababh to heavens.",
  },
  {
    id: 3,
    img: "/assets/harramkabba.jpg",
    title: "Masjid al-Haram",
    desc: "The Great Mosque is the main setting for the Hajj and Umrah pilgrimages that occur in the month of Dhu al-Hijja in the Islamic calendar and at any time of the year, respectively. The Hajj pilgrimage is one of the Pillars of Islam, required of all able-bodied Muslims who can afford the trip.",
  },
  {
    id: 4,
    img: "/assets/gharesoor.jpg",
    title: "Ghar-e-Soor",
    desc: "According to Islamic legends, this cave is where the Holy Prophet and his companion Hazrat Abu Bakr al-Siddiq (R.A) found shelter when fleeing from Quraish search parties.Its very hard to climb the mountain though there is stairway takes almost 1.5 to 2 hours to climb to the top.",
  },
  {
    id: 5,
    img: "/assets/gharehira.jpg",
    title: "Ghar-e-Hira",
    desc: "Jabal al-Hira (Arabic: جبل الحراء) is a mountain which lies about two miles from the Ka’bah. Near the top is a small cave known as the Cave of Hira (Arabic: غار حراء), which is a little less than 4 meters in length and a little more than 1.5 meters in width.",
  },
];

export const amenitiesContent = [
  {
    img: "/assets/hotel.svg",
    title: "STAR ACCOMODATION",
  },
  {
    img: "/assets/breakfast.svg",
    title: "BREAKFAST AND DINNER",
  },

  {
    img: "/assets/security-guard.svg",
    title: "SECURITY",
  },
  {
    title: "TRANSPORT",
    img: "/assets/Path11.svg",
  },
];

export const featuresToursContent = [
  // {
  //   img: "/assets/activity-icon-1.png",
  //   text: "Shopping Oppurtunities.",
  // },
  // {
  //   img: "/assets/activity-icon-1.png",
  //   text: "Sight Seeing Through Double Decker Service.",
  // },
  {
    img: "/assets/activity-icon-2.png",
    text: "sightseeing with clean and company vehicles.",
  },
  {
    img: "/assets/about-icon-2.png",
    text: "Fully Insured Drivers,",
  },
  {
    img: "/assets/What-We-Believe-icon-1.png",
    text: "Professional Tourist Guide.",
  },
  // {
  //   img: "/assets/activity-icon-1.png",
  //   text: "Some quick example text to build on the card title and make up the bulk of the card's content.",
  // },
];

export const hotelsContent = [
  {
    id: 0,
    img: "/assets/Hotel-One.png",
    title: "TDCP RESORT NANKANA",
    price: 4500,
    desc: "A4 Star Hotel is a hotel that provides above-average deluxe service and experience for the guest1. The hotel has a larger range of facilities",
  },
  {
    id: 1,
    img: "/assets/pearlcontinentallahore.png",
    title: "PC HOTEL, LAHORE",
    price: 4500,
    desc: "A4 Star Hotel is a hotel that provides above-average deluxe service and experience for the guest1. The hotel has a larger range of facilities",
  },
  {
    id: 2,
    img: "/assets/avari-hotel-lahore.png",
    title: "AVARI HOTEL, LAHORE",
    price: 4500,
    desc: "A4 Star Hotel is a hotel that provides above-average deluxe service and experience for the guest1. The hotel has a larger range of facilities",
  },
  {
    id: 3,
    img: "/assets/avari-hotel-lahore.png",
    title: "TDCP RESORT NANKANA",
    price: 4500,
    desc: "A4 Star Hotel is a hotel that provides above-average deluxe service and experience for the guest1. The hotel has a larger range of facilities",
  },
];

export const cancellationPolicy = [
  "7 Days before the departure, a 50% deduction",
  "3 Days before the departure, a 75% deduction",
  "Less than 3 Days before the departure, 100% deduction",
  "No amount will be refunded if any person leaves the trip at any stage",
];

export const visaPolicy = [
  // "The Foreign Office (Islamabad) may be requested to establish the “Sikh Facilitation Desk” in Lahore for Sikh Yatris from Canada, UK, & US.",
  // "Integrate the option to book the “Visa process directly through the TDCP website” and facilitate incorporation on the platform as well.",
  "TDCP does not cater Visa Services.",
];
export const terms = [
  "Please bring your National Identity Card (CNIC). The JAWAR TAIBAH reserves the right to refuse any participant, who does not have CNIC, to board the trip without any refund.",
  "The JAWAR TAIBAH reserves the right to substantially change, alter or modify the trip schedule without any prior intimation to participants and/or costs due to unforeseen factors including without limitation traffic jams, landslides, riots, terrorist activity, political turmoil, bad weather or as deemed fit to be in the best interest of the whole group by the Company.",
  "You warrant and affirm that you will not get involved in any character failing activity, including but not limited to the use of obscene language or as the Company or its representative(s) deems fit, during the trip and acknowledge that failure to abide by this provision will entitle the JAWAR TAIBAH to drop you off from rest of the trip without any refund.",
  "Please take care of the other participants’ respect and privacy. The JAWAR TAIBAH and its representative(s) reserve the right to remove any person from the trip without warning in case of non-compliance with this condition.",
  "If, for any unforeseen reason or activity that is beyond the control of the JAWAR TAIBAH , the trip is canceled at any time before departure then participants will be entitled to a refund of their respective amount paid to the JAWAR TAIBAH after 25% deductions from the amount paid and/or charging any administrative costs incurred to make such refunds.",
  "You warrant and affirm that you do not possess any explosives, weapons, or prohibited drugs of any sort throughout the trip. The JAWAR TAIBAH and its representative(s) shall not be held liable for any situation that arises due to your malafide or negligence and you shall hold the JAWAR TAIBAH and its representative(s) harmless and indemnified from any legal or civil consequences arising out of your actions in this regard. Any participant found to be in possession of the aforementioned restricted article(s) shall be immediately dropped from the trip without any refund.",
  "In case of theft, robbery, or loss or damage by any means whatsoever, to the personal belongings of participants, regardless of their monetary value, during the trip, the JAWAR TAIBAH or its representative(s) shall not be held liable for any such loss or damage be it direct or indirect.",
  "You warrant and affirm that you will use/handle any of the Company’s equipment provided to you during the course of this trip will utmost reasonable skill and care and warrant to not damage the Company’s property/equipment and/or any service tool under the possession of JAWAR TAIBAH being used for the trip. If you fail to adhere to this condition, either intentionally or negligently, you shall be bound to pay/reimburse/indemnify the JAWAR TAIBAH for its losses and any consequential damages that may arise from your failure to comply with this condition.",
  "The JAWAR TAIBAH will take all reasonably possible measures to ensure the safety and well-being of participants of the trip, however, in case any unwanted situation arises which is beyond the reasonable control of the JAWAR TAIBAH or its representative(s) and in the presence of any unforeseeable, inevitable event, the JAWAR TAIBAH and/or its representative(s) shall, by any reason whatsoever, not be held liable and responsible for any consequences.",
];

export const includesData = [
  "Fully Insured Drivers",
  "Professional Service",
  "Drivers With Identifications",
  "Driver as Tour Guide",
  "Local Knowledge",
  "Souvenirs / Gifts",
];
