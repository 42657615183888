import React from "react";
import "../common/header.css";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Image1 from "../assets/JT-Logo.png"
function Header() {
  return (
    <nav className="navbar navbar-expand-lg header main_container">
      <div className="inner_container header_inner">
        <div>
          <Link to="/" className="brand">
            <img style={{height:"70px",width:'100px'}} src={Image1}></img>
          
          </Link>
        </div>
        <div style={{display:"flex"}}>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/aboutpage" className="nav-link disabled" aria-disabled="true">
                  About
                </Link>
              </li>
              <li className="nav-item">
                  <HashLink to="/#packages" className="nav-link">
                    Packages
                  </HashLink>
              </li>
              <li className="nav-item">
                <Link to="/gallerypage" className="nav-link">
                  Gallery
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/contactpage" className="nav-link">
                  Contact
                </Link>
              </li>
              {/* <li className="nav-item">
              <Link to="/resultpage" className="nav-link">
                Result
              </Link>
            </li> */}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;
