import React from 'react';
import Image from '../../assets/Picture3.png';
import Image2 from '../../assets/Picture5.png';
import Image3 from '../../assets/Picture2.png';
import '../sikhlearning/sikhlearning.css';

function SikhLearning() {
  return (
    <div className='sikhlearning-container'>
      <div className='row learning-content-3'>
        <div className='col-md-6 col-sm-12 learning-content'>
          <p>The Sikh Shrines</p>
          <h1>SIKH LEARNING FOR ALL AGES</h1>
        </div>
        <div className='col-md-6 col-sm-12 learning-content-1'>
          <p>In the old town of Eminabad, some 15 kms away from Gujrariwala, Gurudwara Rori Sahib is located at the  site where Guru Nanak is believed to have taken refuge from the pillage of Eminabad by Babur.</p>
        </div>
      </div>
      <div className='row justify-content-center Card-2'>
        <div className='col-12 col-md-6 col-lg-3 mb-4'>
          <div class="card">
            <img class="card-img-top" src={Image2} alt="Card image cap" />
            <div class="card-body">
              <h5 className='card-title'>ENRICH YOUR UNDERSTANDING</h5>
              <p className='card-text'>The study suggests these 4 selected Gurdwaras as a potential tourism circuit drawing its inspiration from Guru Nanak’s life and following the path he chose.</p>
            </div>
          </div>
        </div>
        <div className='col-12 col-md-6 col-lg-3 mb-4'>
          <div class="card">
            <img class="card-img-top" src={Image3} alt="Card image cap" />
            <div class="card-body">
              <h5 className='card-title'>DEVELOP A DEEPER CONNECTION</h5>
              <p className='card-text'>The study suggests these 4 selected Gurdwaras as a potential tourism circuit drawing its inspiration from Guru Nanak’s life and following the path he chose.</p>
            </div>
          </div>
        </div>

        <div className='col-12 col-md-6 col-lg-3 mb-4'>
          <div class="card">
            <img class="card-img-top" src={Image} alt="Card image cap" />
            <div class="card-body">
              <h5 className='card-title'>EXPERIENCE THE FRAGRANCE</h5>
              <p className='card-text'>The study suggests these 4 selected Gurdwaras as a potential tourism circuit drawing its inspiration from Guru Nanak’s life and following the path he chose.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SikhLearning;