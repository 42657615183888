import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import "./hotel/hotels.css";
import Image4 from "../assets/person.svg";
import Image5 from "../assets/calendar-alt.svg";
import Image6 from "../assets/vehicle-car.svg";
import Image7 from "../assets/Path12.svg";
import SwiperCore from "swiper";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { QuantitySelector } from "./hotel/QuantitySelector";
import { CURRENCY_CODE } from "../../cardsContent";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
// Initialize SwiperCore modules
SwiperCore.use([Navigation]);

const Car = ({ vehicles, FinalBooking, setFinalBooking }) => {
  // console.log(vehicles);
  const [quantity, setParentQuantity] = useState(0);

  const SelectCar = (h) => {
    var selectedcarArray = FinalBooking.vehicleID
      ? FinalBooking.vehicleID.split(",")
      : [];
    var selectedcarQuantity = FinalBooking.VechicleQty
      ? FinalBooking.VechicleQty.split(",")
      : [];

    if (selectedcarArray.includes(h.id.toString())) {
      let FselectedcarArray = [];
      let FselectedcarQty = [];
      for (let i = 0; i < selectedcarArray.length; i++) {
        if (selectedcarArray[i].toString() !== h.id.toString()) {
          FselectedcarArray.push(selectedcarArray[i]);
          FselectedcarQty.push(selectedcarQuantity[i]);
        }
      }
      setFinalBooking({
        ...FinalBooking,
        vehicleID: FselectedcarArray.toString(),
        VechicleQty: FselectedcarQty.toString(),
      });
      setParentQuantity(0);
    } else {
      if (quantity > 0) {
        selectedcarArray.push(h.id);
        selectedcarQuantity.push(quantity);
        setFinalBooking({
          ...FinalBooking,
          vehicleID: selectedcarArray.toString(),
          VechicleQty: selectedcarQuantity.toString(),
        });
        setParentQuantity(0);
      } else {
        alert("Please select quantity.");
      }
    }
  };
  useEffect(() => {
    //getVehicles();
    const swiper = new SwiperCore(".swiper-container-car", {
      slidesPerView: 3, // Allow variable slide widths
      spaceBetween: 30,
      navigation: {
        prevEl: ".custom-prev-arrow",
        nextEl: ".custom-next-arrow",
      },
    });
  }, []);
  return (
    <div className="book-tour-container m_t_2 box_shadow">
      <div className="banner">
        <img src={Image7}></img>
        <span> Select Your Vehicle</span>
      </div>
      <div className="car-booking">
        <div className="swiper-container-car">
          <div className="swiper-wrapper">
            {vehicles.length
              ? vehicles.map((h, i) => (
                  <div className="swiper-slide" key={i}>
                    <div className="card car_card w-100">
                      <img
                        className="card-img-top "
                        src={h.image}
                        alt="Card image cap"
                      />
                      <div className="card-body text-center">
                        <h1 className="card-text text-center">{h.name}</h1>
                        <div className="car-svgs mb-2">
                          <img src={Image4}></img>
                          <span className="car-text">
                            {h.total_seats} persons
                          </span>
                          <img src={Image5}></img>
                          <span className="car-text">{h.model}</span>
                          <img src={Image6}></img>
                          <span className="car-text">{h.company}</span>
                        </div>
                        <h3 className="p_price">
                          {CURRENCY_CODE} {h.rent}/Tour
                        </h3>
                        <QuantitySelector
                          setParentQuantity={setParentQuantity}
                        />
                        {/* {console.log(FinalBooking)} */}
                        <div
                          className={
                            FinalBooking.vehicleID
                              ? FinalBooking.vehicleID.includes(h.id)
                                ? "selected_car"
                                : "select-car"
                              : "select-car"
                          }
                        >
                          {/* // <Link to="/tourpage#formData"> */}
                          <button onClick={() => SelectCar(h)}>Select</button>
                          {/* // </Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
          <div className="custom-next-arrow" style={{ background: "#07400b" }}>
            <FaArrowRight />
          </div>
          <div className="custom-prev-arrow" style={{ background: "#07400b" }}>
            <FaArrowLeft />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Car;
