import React from 'react'
import Header from '../common/header'
import Carousel from './carousel/carousel'
import Footer from '../common/footer'
import '../gallery/gallerypage.css'
import BannerSecond from '../common/BannerSecond'
function GalleryPage() {
  return (
    <div className='gallery-page-container'>
        <Header/>
        <BannerSecond title="GALLERY" subTitle="JAWAR TAIBAH"/>
        <Carousel/>
        <Footer/>
    </div>
  )
}

export default GalleryPage