import React, { useEffect, useState } from "react";
import { generateSessionId } from "../../../server";

const PaymetGateway = ({ paymentResponse, setpaymentResponse, closeModal }) => {
  const [loading, setLoading] = useState(true);

  const initiateCheckOut = async () => {
    const responce = await generateSessionId(paymentResponse.id, paymentResponse.total);
    //console.log(responce);
    if (responce["data"]) {
      if (responce["data"]["session.id"]) {
        window.Checkout.configure({
          session: {
            id: responce["data"]["session.id"],
          },
        });
        setTimeout(() => {
          window.Checkout.showEmbeddedPage("#embed-target");
          setLoading(false);
        }, 2000);
      } else {
        alert("Something went wrong. Please contact support !");
        closeModal()

      }
    } else {
      alert("Something went wrong. Please contact support !");
      closeModal()
    }
  };

  useEffect(() => {
    initiateCheckOut();
  }, []);
  return (
    <div>
      {loading ? (
        <div class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : null}
      <div id="embed-target"></div>
    </div>
  );
};

export default PaymetGateway;
