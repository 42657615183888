import React from 'react';
// import Image1 from '../../assets/Picture2.png';
// import Image2 from '../../assets/Picture3.png';
// import Image3 from '../../assets/Picture4.png';
// import Image4 from '../../assets/Picture5.png';
// import Image5 from '../../assets/Picture6.png';
// import Image6 from '../../assets/Picture7.png';
import "../card/card.css";
import { Locations } from '../../../cardsContent';
import '../../../App.css'

function Card() {
  return (
    <section className='main_container'>
      <div className="inner_container cards">
        <div className="cards_grid">
        {
          Locations.map((c, i)=>(
            <div class="card">
            <img class="card-img-top" src={c.img} alt="Card image cap" />
            <div class="card_body">
              <h5 className='card-title'>{c.title}</h5>
              <p className='card_text'>{c.desc}</p>
            </div>
          </div>
          ))
        }
        </div>
 

      </div>
    </section>
  );
}

export default Card;
