import React, { useState } from "react";
import Header from "../common/header";
import Footer from "../common/footer";
import "../../App.css";
import Hotels from "./hotel/hotels";

import { Link, useLocation } from "react-router-dom";
import BookingInformation from "./bookinginformation/bookinginformation";
import Car from "./car";
import BannerSecond from "../common/BannerSecond";
import PoliceSquad from "./policeSquad";
function Tourpage() {
  const location = useLocation();
  console.log(location.state);
  const [sharedData, setSharedData] = useState({});
  const [FinalBooking, setFinalBooking] = useState({});

  const {    
    packages,
    chkIn,
    bookingType,
    chkOut, tour}=location.state;
    //console.log(location.state);
  return (
    <>
      <Header />
      <BannerSecond title={bookingType=="static" ?"BOOK YOUR TOUR":"CUSTOMIZE YOUR TOUR"} subTitle="One Last Step" />
      <section className="main_container">
        <div className="inner_container">
        {bookingType !=="static"?
        <>
         {/* <Hotels  chkIn={chkIn} chkOut={chkOut} star={packages.hotel_stars} FinalBooking={FinalBooking} setFinalBooking={setFinalBooking}/> */}
         <Car vehicles={packages.vehicles} FinalBooking={FinalBooking} setFinalBooking={setFinalBooking}/>
        {/* <PoliceSquad police={packages.police} FinalBooking={FinalBooking} setFinalBooking={setFinalBooking}/> */}
        </>
       :null
        }
        
        <BookingInformation id='formData'
          sharedData={sharedData}
          setSharedData={setSharedData}
          packages={packages}
          tour={tour}
          bookingType={bookingType}
          FinalBooking={FinalBooking} 
          setFinalBooking={setFinalBooking}
        />
      </div>
      </section>
      <Footer />
    </>
  );
}

export default Tourpage;
