import React from "react";
import Header from "../common/header";
import PackageDetails from "./packagedetails/packagedetails";
import Footer from "../common/footer";
import "../packages/packagespage.css";
function PackagesPage() {
  return (
    <div className="pacakages-page-container">
      <Header />
      <PackageDetails />
      <Footer />
    </div>
  );
}

export default PackagesPage;
