import React, { useEffect } from 'react';
import Image1 from "../../assets/f1.jpg";
import Image2 from "../../assets/f2.jpg";
import Image3 from "../../assets/f3.jpg";
import Image4 from "../../assets/f9.jpg";
import Image5 from "../../assets/f5.jpg";
import Image6 from "../../assets/f6.jpg";
import Image7 from "../../assets/f7.jpg";
import Image8 from "../../assets/f8.jpg";
import "../carousel/carousel.css";


function CustomSliders(){
  return (
    <div className="slider_container-1">
      <div className='row gallery-first-row'>
        <div className='col-3'>
          <img src={Image1}></img>
        </div>
        <div className='col-3'>
          <img src={Image2}></img>
        </div>
        <div className='col-3'>
          <img src={Image3}></img>
        </div>
        <div className='col-3'>
          <img src={Image4}></img>
        </div>
        </div>
        <div className='row gallery-second-row'>
        <div className='col-3'>
          <img src={Image5}></img>
        </div>
        <div className='col-3'>
          <img src={Image6}></img>
        </div>
        <div className='col-3'>
          <img src={Image7}></img>
        </div>
        <div className='col-3'>
          <img src={Image8}></img>
        </div>
        </div>
    </div>
  )
}

export default CustomSliders;
