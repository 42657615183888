import React from "react";
import Image from "../../assets/template.png";
import "../goldentemple/goldentemple.css";

function Goldentemple({ showNankanaRow = true }) {
  return (
    <div className="temple-container">
      <section className="main_container">
        <div className="inner_container">
        <div className="temple_grid">
        <div className="grid_item_1">
          {/* <img src={Image} alt="Golden Temple" /> */}
        </div>
        <div className="grid_item_2 temple-content">
          <span> JAWAR TAIBAH</span>
          <h1 className="main_heading">Ziarats</h1>
          <p className="notbold m_t_2">
          Saudi Ziarat refers to the visitation of holy sites in Saudi Arabia, particularly those associated with the Prophet Muhammad, his family, and his companions. Ziyarat is an Arabic word meaning "visit," and it is considered to be a virtuous and honorable act in Islam.          </p>
        </div>
      </div>
        </div>
    
      </section>
   
      {showNankanaRow && (
        <section className="main_container nankana">
          <div className="inner_container">
          <h1 className="main_heading">JAWAR TAIBAH</h1>

          <div className="w_50">
            <p className="m_t_2">
            The two most important sites for Saudi Ziarat are Makkah and Madinah, which are also the two holiest cities in Islam. In Makkah, pilgrims visit the Masjid al-Haram (Grand Mosque), which houses the Kaaba, the most sacred site in Islam. They also visit the Jabal al-Nur (Mountain of Light), where the Prophet Muhammad received his first revelation from the angel Gabriel.
            </p>
            <p className="mb-0 m_t_2">
            In Madinah, pilgrims visit the Masjid an-Nabawi (Prophet's Mosque), which is the burial place of the Prophet Muhammad. They also visit the Jannat al-Baqi (Cemetery of Baqi), where many of the Prophet's family and companions are buried.            </p>
          </div>
          </div>
          
        
        </section>
      )}
    </div>
  );
}

export default Goldentemple;
