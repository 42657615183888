import React from "react";
import "../common/footer.css";
import Image1 from "../assets/f1.jpg";
import Image2 from "../assets/f2.jpg";
import Image3 from "../assets/f3.jpg";
import Image4 from "../assets/f9.jpg";
import { BiPhoneCall } from "react-icons/bi";
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import {
  FaFacebookF,
  FaGooglePlusG,
  FaTwitter,
  FaInstagram,
} from "react-icons/fa";

function Footer() {
  return (
    <div className="footer">
      <div className="container-3">
        <div className="row gallery">
          <div className="col-md-12 col-lg-7">
            <h5 className="footer-1-content">Photo Gallery</h5>
            <div className="photo-row d-flex">
              <img src={Image1} alt="Image1" />
              <img src={Image3} alt="Image3" />
              <img src={Image2} alt="Image2" />
              <img src={Image4} alt="Image4" />
            </div>
          </div>
          <div className="col-md-12 col-lg-5">
            <div className="quick-contact mt-4">
              <h5>Quick Contact</h5>
              <a href="tel:+924299231360" className="design-first">
                <BiPhoneCall style={{ color: "white", marginRight: "10px" }} />
                +966 (0) 56 677 4142
              </a>
              <br />
              <span className="design-second">
                <FaLocationDot
                  style={{ color: "white", marginRight: "10px" }}
                />{" "}
                Office # 23, Al Zugaibi Compund, Beside Mandarine Super Market.
                Abizar Gaffari Street. Al Suhman District. Madinah Al Munawarah.
                Saudi Arabia.
              </span>
              <br />
              <a href="mailto:info@tdcp.gop.pk" className="design-third">
                <MdEmail style={{ color: "white", marginRight: "10px" }} />
                info@jawartaibah.com
              </a>
            </div>
            {/* <div className="connect mt-4 mb-4">
              <h5>Connect with us</h5>
              <a
                target="_blank"
                class="links design1"
                href="https://www.facebook.com/TDCPOfficial/"
              >
                <FaFacebookF />
              </a>

              <a
                target="_blank"
                class="links design1"
                href="https://www.facebook.com/TDCPOfficial/"
              >
                <FaGooglePlusG />
              </a> 

              <a
                target="_blank"
                class="links design1"
                href="https://twitter.com/TDCPOFFICIAL"
              >
                <FaTwitter />
              </a>

              <a
                target="_blank"
                class="links design1"
                href="https://www.instagram.com/tdcp_official/"
              >
                <FaInstagram />
              </a>
            </div> */}
          </div>
        </div>
      </div>

      <div className="subcontainer d-flex justify-content-between">
        <div className="text-center text-lg-start">
          <p className="footer-info">
            Copyright &copy; 2023 JAWAR TAIBAH. All Rights Reserved
          </p>
        </div>
        <div className="text-center text-lg-end mt-2 mt-lg-0">
          <p className="footer-info-1">
            {/* Tourism Development Corporation of Punjab  */}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
